<template>
    <div id="box">
        <Header :headerIndex="4" :nextLevel="6"></Header>
        <div class="container">
            <div class="flex-box flex-col-start ptb30 m1340 flex-col-stretch">
                <LeftNav :leftIndex="6"></LeftNav>
                <div class="flex-grow-1 ml20 pt30 plr30 personal member">
                    <div class="fs20 col10 fwb pb30">{{$t('select')}}</div>
                    <div class="flex-box pb40">
                        <a href="javascript:;" class="btn select mr30" @click="select">{{$t('select')}}</a>
                        <a href="javascript:;" class="btn history" @click="gohistory">{{$t('history')}}</a>
                    </div>
                    <div class="fs20 col10 fwb pb25">{{$t('playerprofile')}}</div>
                    <div class="flex-box pb40">
                        <a href="javascript:;" class="btn btns fs16 " :class="type == 4 ? 'new' : ''"
                           @click="type=3">{{$t('newprofile')}}</a>
                        <div class="fs18 plr20 col10">OR</div>
                        <a href="javascript:;" class="btn btns fs16" :class="type == 3 ? 'new' : ''"
                            @click="type=4">{{$t('exsitingprofile')}}</a>
                    </div>
                    <div class="fs20 col10 fwb pb25">{{$t('select')}}</div>
                    <div class="flex-box flex-wrap flex-col-start">
                        <div class="input_item" v-if="type==3">
                            <div class="fs16 col5 pb10">{{$t('email')}}</div>
                            <el-input class="input mb30" placeholder="Enter Email" v-model="email"></el-input>
                        </div>
                        <div class="input_item" v-if="type==3">
                            <div class="fs16 col5 pb10">{{$t('accountnumber')}}</div>
                            <el-input class="input mb30" placeholder="Enter Account Number" v-model="idnum"></el-input>
                        </div>
                        <div class="input_item" v-if="type==3">
                            <div class="fs16 col5 pb10">{{$t('password')}}</div>
                            <el-input class="input mb30" placeholder="Enter Password"  type="password"   v-model="password"></el-input>
                        </div>
                        <div class="input_item" v-if="type==3">
                            <div class="fs16 col5 pb10">{{$t('confirmpassword')}}</div>
                            <el-input class="input mb30" placeholder="Confirm Password"  type="password"   v-model="tpassword"></el-input>
                        </div>
                        <div class="input_item">
                            <div class="fs16 col5 pb10">{{$t('contactperson')}}</div>
                            <el-input :readonly="true" class="input mb30" placeholder="Enter Contact Person Number"
                                v-model="choose"></el-input>
                        </div>
                        
                        <div class="input_item">
                            <div class="fs16 col5 pb10">{{$t('selectmarket')}}</div>
                            <el-select v-model="market" class="input mb30" placeholder="Choose Market">
                                <el-option v-for="(item, index) in options" :key="index" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <!-- <div class="fs20 col10 fwb pb25">CARD MAKING AMOUNT</div>
                    <div class="flex-box mb30 amount_box">
                        <el-input class="input amount" placeholder="Please enter the card printing amount" v-model="price"></el-input>
                        <a href="javascript:;" class="btn select mr40 make" @click="make">MAKE</a>
                    </div> -->
                    <div class="fs20 col10 fwb pb25">{{ $t('invitationcode') }}</div>
                    <div class="flex-box mb30 amount_box">
                        <el-input class="input amount" placeholder="Please enter the card invitation code"
                            v-model="code"></el-input>
                        <a href="javascript:;" class="btn select mr40 make" @click="make">{{$t('submit')}}</a>
                    </div>
                    <div class="liiustrate p40 mb40">
                        <div class="fs20 fwb col10 pb20">LIIUSTRATE</div>
                        <div class="fs16 col3">
                            <div class="">

                                <div class="" v-html="articledetail"></div>
                            </div>
                            <!-- {{ articledetail }} -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import { numberToCurrency } from "../assets/filters";
import cookie from 'vue-cookies'
export default {
    filters: {
        numberToCurrency
    },
    data() {
        return {
            options: [{
                value: '1',
                label: this.$t('left') 
            }, {
                value: '2',
                label: this.$t('right')
            },],
            market: '',
            item: '',
            email: '',
            idnum: '',
            password: '',
            tpassword: '',
            price: '',
            ismake: false,
            articledetail: '',
            choose: '',
            code: '',
            type:3
        };
    },
    created() {
        if (cookie.get('select')) {
            var select = cookie.get('select')
            console.log(select)
            this.item = select.item
            this.choose = '【' + this.item.id + '】' + this.item.idnum
            console.log(this.item)
            this.market = select.index.toString()
        }
        if(this.$route.query.choosetype){
            this.type=this.$route.query.choosetype
        }
        this.config()
    },
    methods: {
        config() {
            this.$api.config({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.articledetail = res.data.member
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
						message: res.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
                }
            })
        },
        gohistory() {
            this.$router.push({ path: '/history' })
        },
        select() {
            this.$router.push({ path: '/map', query: { type: 0,choosetype:this.type } })
        },
        make() {
            if (!this.ismake) {
                if (!this.item) {
                    return this.$message({
                        message: '请选择',
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                    // return this.$message.error('请选择')
                }
                console.log(this.type)
                if(this.type==3){
                    if (!this.email) {
                        return this.$message({
                            message: '请填写邮箱',
                            type: 'error',
                            offset: window.screen.height / 2
                        })
                        // return this.$message.error('请填写邮箱')
                    }
                    if (!this.idnum) {
                        return this.$message({
                            message: '请填写账号',
                            type: 'error',
                            offset: window.screen.height / 2
                        })
                        // return this.$message.error('请填写账号')
                    }
                    if (!this.password) {
                        return this.$message({
                            message: '请填写登陆密码',
                            type: 'error',
                            offset: window.screen.height / 2
                        })
                        // return this.$message.error('请填写登陆密码')
                    }
                    if (!this.tpassword) {
                        return this.$message({
                            message: '请填写确认密码',
                            type: 'error',
                            offset: window.screen.height / 2
                        })
                        // return this.$message.error('请填写确认密码')
                    }
                    if (this.tpassword != this.password) {
                        return this.$message({
                            message: '两次密码不同',
                            type: 'error',
                            offset: window.screen.height / 2
                        })
                        // return this.$message.error('两次密码不同')

                    }
                }   
               
                
                // if(!this.price){
                //     return this.$message.error('请填写金额')
                // }
                if (!this.code) {
                    return this.$message({
                        message: '请填写注册码',
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                    // return this.$message.error('请填写注册码')
                }
                this.ismake = true
                var data = { email: this.email, idnum: this.idnum, password: this.password, code: this.code, access_id: this.item.id, position: this.market ,type:this.type}
                console.log(data)
                this.$api.apiHelpregister(data).then(ret => {
                    if (ret.code == 1) {
                        cookie.remove('select');
                        this.item = ''
                        this.choose = ''

                        this.email = ''
                        this.idnum = ''

                        this.password = ''
                        this.tpassword = ''
                        this.price = ''
                        this.market = ''
                        this.code = ''
                        this.$message({
                            message: ret.msg,
                            type: 'success',
                            offset: window.screen.height / 2
                        })
                        // this.$message.success(ret.msg)


                    } else {
                        this.ismake = false
                        this.$message({
                            message: ret.msg,
                            type: 'error',
                            offset: window.screen.height / 2
                        })
                        // this.$message.error(ret.msg)
                    }
                })
            }
        }
    }
};
</script>
